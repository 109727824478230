import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle,
    DialogDescription,
    DialogFooter,
    DialogClose,
  } from "../../../components/ui/dialog";
  import {
    Tabs,
    TabsList,
    TabsTrigger,
    TabsContent,
  } from "../../../components/ui/tabs";
  import { Button } from "../../../components/ui/button";
  export const EmployeeDetailsModal = ({ isOpen, onClose, employee }) => {
    if (!employee) return null;
  
    const sections = [
      {
        title: "Personal",
        data: [
          { label: "Phone", value: "1234567890" },
          { label: "Full Name", value: employee.name },
          { label: "Gender", value: "Male" },
          { label: "Marital Status", value: "Single" },
          { label: "Blood Group", value: "O+" },
          { label: "Date of Birth", value: "01-01-1990" },
          { label: "Father's Name", value: "John Doe Sr." },
          { label: "Mother's Name", value: "Jane Doe" },
          { label: "Aadhar Card", value: "1111-1111-1111" },
          { label: "PAN Card", value: "XYZAB9944C" },
          { label: "Religion", value: "Not Specified" },
        ],
      },
      {
        title: "Contact",
        data: [
          { label: "Email Personal", value: "john.personal@example.com" },
          { label: "Email Official", value: employee.email },
          { label: "Phone Official", value: "9876543210" },
          { label: "Emergency Contact Name", value: "Jane Doe" },
          { label: "Emergency Contact Phone", value: "1231231234" },
          { label: "PIN Code", value: "500001" },
          { label: "State", value: "Telangana" },
          { label: "District", value: "Hyderabad" },
          { label: "City", value: "Hyderabad" },
          { label: "Town", value: "Gachibowli" },
          { label: "Address Line", value: "123 Main St, Apt 4B" },
        ],
      },
      {
        title: "Work",
        data: [
          { label: "Company", value: "WFM" },
          { label: "Role", value: "Dolffin" },
          { label: "Status", value: "Active" },
          { label: "Department", value: employee.department },
          { label: "Employment Type", value: "Full Time" },
          { label: "Reporting To", value: "John Rambo" },
          { label: "Area Manager", value: "500001001" },
          { label: "Work Status", value: "Available" },
          { label: "Resigned", value: "No" },
        ],
      },
      {
        title: "Bank",
        data: [
          { label: "Bank Name", value: "State Bank of India" },
          { label: "Account Type", value: "Savings Account" },
          { label: "Account Number", value: "1234567890123456" },
          { label: "IFSC Code", value: "SBIN0000621" },
          { label: "Bank Branch", value: "Hyderabad" },
        ],
      },
      {
        title: "Documents",
        data: [
          {
            label: "Passport Size Pic",
            value: "/placeholder.svg?height=100&width=100",
            type: "image",
          },
          { label: "Aadhar Card", value: "#", type: "pdf" },
          { label: "PAN Card", value: "#", type: "pdf" },
          { label: "Driving License", value: "#", type: "pdf" },
          { label: "Bank Statement", value: "#", type: "pdf" },
        ],
      },
      {
        title: "Date",
        data: [
          { label: "Training Join", value: "01-01-2023" },
          { label: "Training Complete", value: "15-01-2023" },
          { label: "Job Start", value: employee.joinDate },
          { label: "Job Exit", value: "N/A" },
          { label: "Last Login", value: "10-05-2023" },
          { label: "Created On", value: "01-01-2023" },
          { label: "Updated On", value: "10-05-2023" },
        ],
      },
      {
        title: "Info",
        data: [
          { label: "Created By", value: "1234567890" },
          { label: "Updated By", value: "Self" },
          { label: "Submit", value: "TRUE" },
        ],
      },
    ];
  
    return (
      <Dialog open={isOpen} onOpenChange={onClose}>
        <DialogContent className="max-w-4xl max-h-[80vh] overflow-y-auto bg-white p-0">
          <DialogHeader className="sticky top-0 z-10 p-6 pb-4 bg-white border-b">
            <DialogTitle className="text-2xl font-semibold text-left ">
              Employee Details
            </DialogTitle>
            <DialogDescription className="text-left">
              Detailed information about {employee.name}
            </DialogDescription>
          </DialogHeader>
          <Tabs defaultValue={sections[0].title.toLowerCase()} className="w-full">
            <TabsList className="flex justify-start p-2 bg-gray-100 border-b">
              {sections.map((section) => (
                <TabsTrigger
                  key={section.title}
                  value={section.title.toLowerCase()}
                  className="px-4 py-2 text-sm font-medium"
                >
                  {section.title}
                </TabsTrigger>
              ))}
            </TabsList>
            <div className="p-6">
              {sections.map((section) => (
                <TabsContent
                  key={section.title}
                  value={section.title.toLowerCase()}
                >
                  <h3 className="mb-4 text-lg font-semibold">{section.title}</h3>
                  <div className="space-y-4">
                    {section.data.map((item, index) => (
                      <div
                        key={index}
                        className="flex justify-between py-2 border-b border-gray-200"
                      >
                        <span className="font-medium text-gray-600">
                          {item.label}:
                        </span>
                        {item.type === "image" ? (
                          <img
                            src={item.value}
                            alt={item.label}
                            width={100}
                            height={100}
                            className="rounded"
                          />
                        ) : item.type === "pdf" ? (
                          <a
                            href={item.value}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-blue-600 hover:underline"
                          >
                            View PDF
                          </a>
                        ) : (
                          <span className="text-gray-800">{item.value}</span>
                        )}
                      </div>
                    ))}
                  </div>
                </TabsContent>
              ))}
            </div>
          </Tabs>
          <DialogFooter className="sticky bottom-0 z-10 justify-end p-3 bg-white border-t ">
            <DialogClose asChild>
              <Button type="button">Close</Button>
            </DialogClose>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    );
  };