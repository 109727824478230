import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  employeesList: [],
  isLoading: false,
  counters: [],
  logs: [],
};

export const appLayoutSlice = createSlice({
  name: "app-layout",
  initialState,
  reducers: {
    setEmployeeList: (state, action) => {
      state.employeesList = action.payload;
    },
    setCounters: (state, action) => {
      state.counters = action.payload;
    },
    setLogs: (state, action) => {
      state.logs = action.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
  },
});

export const { setEmployeeList, setIsLoading, setCounters, setLogs } =
  appLayoutSlice.actions;
export const appLayoutReducer = appLayoutSlice.reducer;