import React, { useState, useCallback } from "react";
import { Button } from "../ui/button";
import {
  Menu,
  X,
  Home,
  Users,
  UserPlus,
  Truck,
  ShoppingCart,
  Webhook,
  Settings,
  LogOut,
  User,
  FileText,
} from "lucide-react";
import { NavLink } from "react-router-dom";
import EventBus from "../../services/utilities/event.bus.service";
import { EventList } from "../../../src/api/event-list";
import { getTokenUserDataFromPersistence } from "../../services/auth/auth.service";

const menuItems = [
  { name: "Home", icon: <Home className="w-5 h-5" />, href: "/dashboard" },
  {
    name: "Employees-S",
    icon: <Users className="w-5 h-5" />,
    href: "/dashboard/employees",
  },
  {
    name: "Employees-P",
    icon: <Users className="w-5 h-5" />,
    href: "/dashboard/employees-p",
  },
  {
    name: "Customers",
    icon: <UserPlus className="w-5 h-5" />,
    href: "/dashboard/customers",
  },
  {
    name: "Vendors",
    icon: <Truck className="w-5 h-5" />,
    href: "/dashboard/vendors",
  },
  {
    name: "Orders",
    icon: <ShoppingCart className="w-5 h-5" />,
    href: "/dashboard/orders",
  },
  {
    name: "Webhook",
    icon: <Webhook className="w-5 h-5" />,
    href: "/dashboard/webhook",
  },
  {
    name: "Settings",
    icon: <Settings className="w-5 h-5" />,
    href: "/dashboard/settings",
  },
  {
    name: "Logs",
    icon: <FileText className="w-5 h-5" />,
    href: "/dashboard/logs",
  },
];

export const Sidebar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(true);
  // const [activeMenu, setActiveMenu] = useState("Home");
  // const handleMenuClick = (menuName) => {
  //   setActiveMenu(menuName);
  // };
  const logout = useCallback(() => {
      EventBus.dispatch(EventList.AUTH.LOGOUT, 'manual');
  }, []);

  const userData = getTokenUserDataFromPersistence()

  return (
    <aside
      className={`bg-white  flex flex-col   ${
        isMenuOpen ? "w-64" : "w-20"
      } transition-all duration-300 ease-in-out`}
    >
      <div className="flex items-center justify-between p-4">
        <h1
          className={`text-xl font-semibold  ${
            isMenuOpen ? "block" : "hidden"
          }`}
        >
          Dashboard
        </h1>
        <Button
          variant="ghost"
          size="icon"
          onClick={() => setIsMenuOpen(!isMenuOpen)}
          className=""
        >
          {isMenuOpen ? (
            <X className="w-6 h-6" />
          ) : (
            <Menu className="w-6 h-6 " />
          )}
        </Button>
      </div>
      <nav className="h-full mt-8">
        {menuItems.map((item) => (
          <NavLink
            end={item.name === "Home"}
            key={item.name}
            to={item.href}
            className={({ isActive }) =>
              isActive
                ? "bg-blue-100 text-blue-600 font-semibold w-full  justify-start flex items-center px-4 py-2 rounded-md"
                : " hover:bg-gray-100  font-semibold text-black  w-full justify-start flex items-center px-4 py-2 rounded-md"
            }
          >
            {item.icon}
            <span className={`ml-4 w-full ${isMenuOpen ? "block" : "hidden"}`}>
              {item.name}
            </span>
          </NavLink>
        ))}
      </nav>
      <div className="flex flex-col justify-end py-4 border-t ">
        <NavLink
          to={"/dashboard/profile"}
          className={({ isActive }) =>
            isActive
              ? "bg-blue-100 text-blue-600 font-semibold w-full  justify-start flex items-center px-4 py-2 rounded-md"
              : " hover:bg-gray-100  font-semibold text-black  w-full justify-start flex items-center px-4 py-2 rounded-md"
          }
        >
          <User className="w-5 h-5" />
          <span className={`ml-4 w-full ${isMenuOpen ? "block" : "hidden"}`}>
            {userData?.user?.fName}
          </span>
        </NavLink>
        <Button
          variant="ghost"
          className="justify-start w-full px-4 py-3 text-gray-600 hover:bg-gray-100 "
          onClick={logout}
        >
          <LogOut className="w-5 h-5" />
          <span
            className={`pl-1.5 text-left w-full ${
              isMenuOpen ? "block" : "hidden"
            }`}
          >
            Log out
          </span>
        </Button>
      </div>
    </aside>
  );
};
export default Sidebar;