import {
    setEmployeeList,
    setIsLoading,
  } from "../../redux/reducers/app-layout-reducer";
  import { store } from "../../redux/store";
  import { getTokenFromPersistance } from "../auth/auth.service";
  import axios from "../utilities/axios.service";
  
  export const getEmployees = async () => {
    const tokenFromStorage = getTokenFromPersistance();
    store.dispatch(setIsLoading(true));
    try {
      const res = await axios.get(`/api/v1/employee/getAllEmployees`, {
        headers: { Authorization: `${tokenFromStorage}` },
      });
      store.dispatch(setEmployeeList(res.data));
      store.dispatch(setIsLoading(false));
      return res.data;
    } catch (error) {
      store.dispatch(setIsLoading(false));
  
      console.log(error);
    }
  };