import { Button } from "../../../components/ui/button";
import React from "react";
import { formatDate } from "src/utils/shared";
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
} from "src/components/ui/sheet";
import { SummaryItem } from "src/components/ui/summary-item";

export const EmployeeDetailsSlideDrawer = ({
  isOpen,
  onClose,
  employee,
}) => {
  if (!employee) return <></>;

  return (
    <Sheet open={isOpen} onOpenChange={onClose}>
      <SheetContent className="overflow-y-auto md:max-w-[40%]  ">
        <SheetHeader className="pb-4 mb-4 border-b">
          <SheetTitle className="text-2xl font-semibold">
            Employee Details
          </SheetTitle>
          <SheetDescription>
            Detailed information about {employee.employeeFullName}
          </SheetDescription>
        </SheetHeader>
        <div className="space-y-6 ">
          <div className="grid gap-4">
            <h3 className="px-6 py-3 mb-2 text-lg font-semibold text-gray-600 bg-gray-200">
              Personal Information
            </h3>
            <div className="grid grid-cols-1 gap-2 ">
              <SummaryItem
                title="Full name"
                value={employee.employeeFullName}
              />
              <SummaryItem
                title="Date of birth"
                value={formatDate(employee.employeeDOB)}
              />
              <SummaryItem title="Gender" value={employee.employeeGender} />
              <SummaryItem
                title="Phone number"
                value={employee.employeePhone}
              />
              <SummaryItem title="Religion" value={employee.employeeReligion} />
              <SummaryItem
                title="Marital status"
                value={employee.employeeMaritalStatus}
              />
              <SummaryItem
                title="Blood group"
                value={employee.employeeBloodGroup}
              />
              <SummaryItem
                title="Personal email"
                value={employee.employeeEmailPersonal}
              />
              <SummaryItem
                title="Father's name"
                value={employee.employeeFatherName}
              />
              <SummaryItem
                title="Mother's name"
                value={employee.employeeMotherName}
              />
              <SummaryItem
                title="Aadhar Card"
                value={employee.employeeAadharCard}
              />
              <SummaryItem title="PAN card" value={employee.employeePANCard} />
              <SummaryItem
                title="Emergency contact person"
                value={employee.employeeEmergencyContactName}
              />
              <SummaryItem
                title="Emergency contact phone"
                value={employee.employeeEmergencyContactPhone}
              />
            </div>
          </div>
          <div className="grid gap-4 ">
            <h3 className="px-6 py-3 mb-2 text-lg font-semibold text-gray-600 bg-gray-200">
              Bank Information
            </h3>
            <div className="grid grid-cols-1 gap-2 ">
              <SummaryItem
                title="Bank name"
                value={employee.employeeBankName}
              />
              <SummaryItem
                title="Bank name"
                value={employee.employeeBankName}
              />
              <SummaryItem
                title="Account number"
                value={employee.employeeBankAccountNo}
              />
              <SummaryItem
                title="Account branch"
                value={employee.employeeBankBranch}
              />
              <SummaryItem
                title="Account type"
                value={employee.employeeBankAccountType}
              />
              <SummaryItem
                title="Bank IFSC"
                value={employee.employeeBankIFSC}
              />
              <SummaryItem
                title="Phone number"
                value={employee.employeePhone}
              />
            </div>
          </div>
          <div className="grid gap-4 ">
            <h3 className="px-6 py-3 mb-2 text-lg font-semibold text-gray-600 bg-gray-200">
              Residence Information
            </h3>
            <div className="grid grid-cols-1 gap-2">
              <SummaryItem
                title="Address state"
                value={employee.employeeAddressState}
              />
              <SummaryItem
                title="Address City"
                value={employee.employeeAddressCity}
              />
              <SummaryItem
                title="Address town"
                value={employee.employeeAddressTown}
              />
              <SummaryItem
                title="Address line"
                value={employee.employeeAddressLine}
              />
              <SummaryItem
                title="Address PIN Code"
                value={employee.employeeAddressPINCode}
              />
            </div>
          </div>
          <div className="grid gap-4">
            <h3 className="px-6 py-3 mb-2 text-lg font-semibold text-gray-600 bg-gray-200">
              Company Information
            </h3>
            <div className="grid grid-cols-1 gap-2 ">
              <SummaryItem
                title="Company name"
                value={employee.employeeCompany}
              />
              <SummaryItem
                title="Department"
                value={employee.employeeDepartment}
              />
              <SummaryItem title="Role" value={employee.employeeRole} />
              <SummaryItem title="Status" value={employee.employeeStatus} />
              <SummaryItem
                title="Date onboarded"
                value={formatDate(employee.createdAt)}
              />
              <SummaryItem
                title="Employment type"
                value={employee.employeeEmploymentType}
              />
            </div>
          </div>
        </div>
        <SheetClose asChild>
          <Button className="mt-4">Close</Button>
        </SheetClose>
      </SheetContent>
    </Sheet>
  );
};