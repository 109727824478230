import { EventList } from "../../api/event-list";
import axios from "../utilities/axios.service";
import EventBus from "../utilities/event.bus.service";
import Encryption from "../utilities/encryption.service"; 

export const parseJwt = (token) => {
    try {
        token = token.split('Begger ')[1];
        let base64Url = token.split('.')[1];
        let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        let jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
        return JSON.parse(jsonPayload);
    } catch (error) {
        return null;
    }
}

export const getUserInforFromToken = () => {
    return parseJwt(sessionStorage["token"]);
}

export const isAuthenticated = (token) => {
    if (token && parseJwt(token)) {
        return true;
    } else {
        return false;
    }
}

export const logout = async (type) => {
    try {
        const tokenFromStorage = getTokenFromPersistance();
        const data = await axios.post('api/public/auth/logout', {
            logoutType: type
        }, {headers: {'Authorization': `${tokenFromStorage}`}});
        if (data?.data?.success) {
            EventBus.dispatch(EventList.AUTH.LOGOUT_SUCCESSFUL);
        }
    } catch (error) {
        console.log("error",error)
    }
}

export const sendOTP = async (phoneNumber) => {
    try {
        const data = await axios.post('api/public/auth/login' , {
            empPhone: phoneNumber
        })
        if (data?.data?.success) {
            EventBus.dispatch(EventList.AUTH.OTP_SEND_SUCCESSFULLY);
        } else {
            EventBus.dispatch(EventList.AUTH.OTP_SENDING_FAILED, {message: data.message});
        }
    } catch (error) {
        console.error("error msg",error);
        EventBus.dispatch(EventList.AUTH.OTP_SENDING_FAILED, error);
    }
}

export const callBackupLogin = async (phoneNumber, mode) => {
    try {
        const data = await axios.post('api/public/auth/blogin', {
            "empPhone": phoneNumber,
            "otpMedium": mode
        }, false);
        if (data.data.success) {
            EventBus.dispatch(EventList.AUTH.OTP_SEND_SUCCESSFULLY);
        } else {
            EventBus.dispatch(EventList.AUTH.OTP_SENDING_FAILED, {message: data.message});
        }
    } catch (error) {console.error("error",error);
        EventBus.dispatch(EventList.AUTH.OTP_SENDING_FAILED, error);
    }
}

export const submitOTP = async (data) => {
    try {
        const output = await axios.post('api/public/auth/otp', data, false);
        console.log("output",output);
        if (output?.data?.success) {
            EventBus.dispatch(EventList.AUTH.LOGIN_SUCCESSFULL, output);
        } 
        else {
            EventBus.dispatch(EventList.AUTH.LOGIN_FAILED,{message:output?.data?.message});
        }
    } catch (error) {
        console.error("error",error);
        EventBus.dispatch(EventList.AUTH.LOGIN_FAILED, error);
    }
}

export const setTokenForPersistance = (token) => {
    sessionStorage[Encryption.encrypt('token')] = Encryption.encrypt(token);
}

export const getTokenFromPersistance = () => {
    return Encryption.decrypt(sessionStorage[Encryption.encrypt('token')]);
}

export const deleteTokenFromPersistence = () => {
    sessionStorage.removeItem(Encryption.encrypt('token'));
    sessionStorage.removeItem('user_data');
}

export const checkTokenExistence = () => {
    if(sessionStorage[Encryption.encrypt('token')] && sessionStorage[Encryption.encrypt('token')] != "") {
        return true;
    } else {
        return false;
    }
}

export const setTokenUserDataForPersistence = (tokenUserData) => {
    sessionStorage['user_data'] = JSON.stringify(tokenUserData);
}

export const getTokenUserDataFromPersistence = () => {
    return JSON.parse(sessionStorage['user_data']);
}

export const submitIMLogoutRequest = async (data) => {
    try {
        const tokenFromStorage = getTokenFromPersistance();
        console.log("get token from pest", tokenFromStorage);
        const output = await axios.post('api/public/auth/imlogout', data, {headers: {'Authorization': `${tokenFromStorage}`}});
        console.log("output",output);
        if (output.success) {
            EventBus.dispatch(EventList.AUTH.LOGIN_SUCCESSFULL, output);
        } else {
            EventBus.dispatch(EventList.AUTH.LOGIN_FAILED,{message:output?.data?.message});
        }
    } catch (error) {console.error("error",error);
        EventBus.dispatch(EventList.AUTH.LOGIN_FAILED, error);
    }
}