import { useEffect, useLayoutEffect, useState } from 'react';
import "./App.css";
import { RouterProvider,  } from "react-router-dom";
import masterRouter from "../../routes/masterRoutes";
import EventBus from '../../services/utilities/event.bus.service';
import { EventList } from '../../api/event-list';
import { logout } from '../../services/auth/auth.service';
import { useDispatch } from 'react-redux';
import { action_deleteToken } from '../../redux/actions/auth.actions';

const App = () => {
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    EventBus.on(EventList.AUTH.LOGOUT, (type) => {
      logout(type);
    });

    EventBus.on(EventList.AUTH.LOGOUT_SUCCESSFUL, () => {
      dispatch(action_deleteToken());
      window.location.href = window.location.origin + '/login';
    })
  }, [])
  useEffect(() => () => {
    EventBus.off(EventList.AUTH.LOGOUT, (type) => {
      logout(type);
    });
  });
  return <RouterProvider router={masterRouter} />
}

export default App;
