import { Card, CardContent } from "../../components/ui/card";
import React, { useEffect } from "react";
import { PageTitle } from "../../components/ui/page-title";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../components/ui/table";
import { useSelector } from "react-redux";
import { getCounters } from "../../services/counters/counters.service";

const Dashboard = () => {
  const { token } = useSelector((state) => state.auth);
  useEffect(() => {
    if (token) {
      getCounters(); 
    }
  }, [token]);

  const { counters, isLoading } = useSelector((state) => state.appLayout);

  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (counters.length === 0) {
    return <></>;
  }
  return (
    <div className="space-y-10">
      <PageTitle>Home</PageTitle>
      <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3">
        <Card>
          <CardContent className="p-6">
            <h3 className="mb-2 text-lg font-semibold">Total Revenue</h3>
            <p className="text-3xl font-bold">$124,563</p>
          </CardContent>
        </Card>
        <Card>
          <CardContent className="p-6">
            <h3 className="mb-2 text-lg font-semibold">New Customers</h3>
            <p className="text-3xl font-bold">+2,845</p>
          </CardContent>
        </Card>
        <Card>
          <CardContent className="p-6">
          < h3 className="mb-2 text-lg font-semibold">Total Orders</h3>
            <p className="text-3xl font-bold">1,234</p>
          </CardContent>
        </Card>
      </div>
      <Table>
        <TableHeader>
          <TableRow className="uppercase bg-gray-100 hover:bg-gray-100">
            <TableHead></TableHead>
            <TableHead className="text-right ">OTP Sent</TableHead>
            <TableHead className="text-right ">OTP Verified</TableHead>
            <TableHead className="text-right ">OTP Failed</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          <TableRow>
            <TableCell>SMS</TableCell>
            <TableCell className="text-right ">
              {counters[0].smsOTPSent}
            </TableCell>
            <TableCell className="text-right ">
              {counters[0].smsOTPVerified}
            </TableCell>
            <TableCell className="text-right ">
              {counters[0].smsOTPFailed}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>WhatsApp</TableCell>
            <TableCell className="text-right ">
              {counters[0].whatsappOTPSent}
            </TableCell>
            <TableCell className="text-right ">
              {counters[0].whatsappOTPVerified}
            </TableCell>
            <TableCell className="text-right ">
              {counters[0].whatsappOTPFailed}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Email</TableCell>
            <TableCell className="text-right ">
              {counters[0].emailOTPSent}
            </TableCell>
            <TableCell className="text-right ">
              {counters[0].emailOTPVerified}
            </TableCell>
            <TableCell className="text-right ">
              {counters[0].emailOTPFailed}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Voice</TableCell>
            <TableCell className="text-right ">
              {counters[0].voiceOTPSent}
            </TableCell>
            <TableCell className="text-right ">
              {counters[0].voiceOTPVerified}
            </TableCell>
            <TableCell className="text-right ">
              {counters[0].voiceOTPFailed}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  );
};
export default Dashboard;