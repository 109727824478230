import React, { useEffect, useState } from "react";
import { PageTitle } from "../../components/ui/page-title";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../components/ui/table";
import { useSelector } from "react-redux";
import { getLogs } from "../../services/gldlogs/logs.service";
import { format, parseISO } from "date-fns";
import { Button } from "../../components/ui/button";
import { LogDetails } from "../../features/gld-logs/components/log-details";

const Logs = () => {
  const [selectedLog, setSelectedLog] = useState({});
  const [open, setOpen] = useState(false);
  useEffect(() => {
    getLogs();
  }, []);

  const { logs, isLoading } = useSelector((state) => state.appLayout);

  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (logs.length === 0) {
    return <></>;
  }
  return (
    <>
      <LogDetails log_details={selectedLog} open={open} setOpen={setOpen} />
      <div className="space-y-10 ">
        <PageTitle>Logs</PageTitle>

        <Table>
          <TableHeader>
            <TableRow className="uppercase bg-gray-100 hover:bg-gray-100">
              <TableHead>Timestamp</TableHead>
              <TableHead>App name</TableHead>
              <TableHead>Level</TableHead>
              <TableHead className="text-right "></TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {logs.map((log) => {
              return (
                <TableRow key={log._id}>
                  <TableCell>
                    {format(
                      parseISO(log.dateTimeStamp),
                      "do MMMM, yyyy ',' hh:mm a"
                    )}
                  </TableCell>
                  <TableCell>{log.app.name}</TableCell>
                  <TableCell>{log.app.level}</TableCell>
                  <TableCell className="text-right ">
                    <Button
                      onClick={() => {
                        setSelectedLog(log);
                        if (selectedLog) {
                          setOpen(true);
                        }
                      }}
                      variant="link"
                    >
                      View Details
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
    </>
  );
};

export default Logs;