import { format, parseISO } from "date-fns";
export const formatTableDate = (dateString) => {
  try {
    return format(parseISO(dateString), "do MMMM, yyyy");
  } catch (error) {
    return dateString ?? "N/A";
  }
};

export const formatDate = (dateString) => {
  try {
    return format(parseISO(dateString), "do MMMM, yyyy");
  } catch (error) {
    return dateString ?? "N/A";
  }
};