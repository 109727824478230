import { useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import "./Loader.css";
import { EventList } from "../../api/event-list";
import EventBus from "../../services/utilities/event.bus.service";
export default function Loader() {
  const opacityRef = useRef(null);
  const toggleLoader = (show) => {
    if (!show) opacityRef?.current?.classList?.remove("modal-open");
    else opacityRef?.current?.classList?.add("modal-open");
  };
  useEffect(() => {
    EventBus.on(EventList.CORE.SHOW_LOADER, () => {
      toggleLoader(true);
    });
    EventBus.on(EventList.CORE.HIDE_LOADER, () => {
      toggleLoader(false);
    });
  }, []);
  useEffect(() => () => {
    EventBus.off(EventList.CORE.SHOW_LOADER, () => {
      toggleLoader(true);
    });
    EventBus.off(EventList.CORE.HIDE_LOADER, () => {
      toggleLoader(false);
    });
  });
  return ReactDOM.createPortal(
    <>
      <div className="modal" ref={opacityRef} onClick={toggleLoader}>
        <div className="loader"></div>
      </div>
    </>,
    document.getElementById("loader-root")
  );
}
