import { useState } from "react";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../components/ui/select";
import { Input } from "../../../components/ui/input";
import { Label } from "../../../components/ui/label";
import { Button } from "../../../components/ui/button";

const initialSearchFormData = {
  filterText: "",
  phoneNumber: "",
  gender: "Male",
  maritalStatus: "",
  bloodGroup: "O-",
  religion: "",
  pin: "",
  state: "",
  district: "",
  city: "",
  company: "WD-WFM",
  role: "Staff",
  status: "Active",
  department: "",
  employmentType: "",
  bank: "",
  accountType: "",
  workStatus: "Available",
  dateType: "",
  dateFrom: "",
  dateTo: "",
  areaManager: "500015001",
};
export const EmployeeSearchForm = ({ onSearch }) => {
  const [formData, setFormData] = useState(initialSearchFormData);
  const [recordsFound, setRecordsFound] = useState(50);

  const handleInputChange = (field, value) => {
    if (field === "phoneNumber" && value) {
      const cleaned = value.replace(/\D/g, "").slice(0, 10);
      setFormData((prev) => ({ ...prev, [field]: cleaned }));
    } else {
      setFormData((prev) => ({ ...prev, [field]: value }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSearch(formData);
  };

  const handleReset = () => {
    setFormData(initialSearchFormData);
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="p-4 space-y-4 bg-white rounded-lg shadow"
    >
      <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
        <div className="flex items-center justify-between col-span-full">
          <Input
            placeholder="Filter keyword here"
            value={formData.filterText}
            onChange={(e) => handleInputChange("filterText", e.target.value)}
            className="flex-grow mr-4"
          />
          <span className="text-sm text-gray-600">
            Records found = {recordsFound}
          </span>
        </div>

        <div>
          <Label htmlFor="phoneNumber">Phone Number</Label>
          <Input
            id="phoneNumber"
            value={formData.phoneNumber}
            onChange={(e) => handleInputChange("phoneNumber", e.target.value)}
            placeholder="Enter 10 digit number"
          />
        </div>

        <div>
          <Label htmlFor="gender">Gender</Label>
          <Select
            value={formData.gender}
            onValueChange={(value) => handleInputChange("gender", value)}
          >
            <SelectTrigger>
              <SelectValue placeholder="Select gender" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="Male">Male</SelectItem>
              <SelectItem value="Female">Female</SelectItem>
              <SelectItem value="Other">Other</SelectItem>
            </SelectContent>
          </Select>
        </div>

        <div>
          <Label htmlFor="maritalStatus">Marital Status</Label>
          <Select
            value={formData.maritalStatus}
            onValueChange={(value) => handleInputChange("maritalStatus", value)}
          >
            <SelectTrigger>
              <SelectValue placeholder="Select marital status" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="Single">Single</SelectItem>
              <SelectItem value="Married">Married</SelectItem>
              <SelectItem value="Divorced">Divorced</SelectItem>
              <SelectItem value="Widowed">Widowed</SelectItem>
            </SelectContent>
          </Select>
        </div>

        <div>
          <Label htmlFor="bloodGroup">Blood Group</Label>
          <Select
            value={formData.bloodGroup}
            onValueChange={(value) => handleInputChange("bloodGroup", value)}
          >
            <SelectTrigger>
              <SelectValue placeholder="Select blood group" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="A+">A+</SelectItem>
              <SelectItem value="A-">A-</SelectItem>
              <SelectItem value="B+">B+</SelectItem>
              <SelectItem value="B-">B-</SelectItem>
              <SelectItem value="O+">O+</SelectItem>
              <SelectItem value="O-">O-</SelectItem>
              <SelectItem value="AB+">AB+</SelectItem>
              <SelectItem value="AB-">AB-</SelectItem>
            </SelectContent>
          </Select>
        </div>

        <div>
          <Label htmlFor="religion">Religion</Label>
          <Select
            value={formData.religion}
            onValueChange={(value) => handleInputChange("religion", value)}
          >
            <SelectTrigger>
              <SelectValue placeholder="Select religion" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="Hindu">Hindu</SelectItem>
              <SelectItem value="Muslim">Muslim</SelectItem>
              <SelectItem value="Christian">Christian</SelectItem>
              <SelectItem value="Sikh">Sikh</SelectItem>
              <SelectItem value="Buddhist">Buddhist</SelectItem>
              <SelectItem value="Other">Other</SelectItem>
            </SelectContent>
          </Select>
        </div>

        <div>
          <Label htmlFor="state">State</Label>
          <Select
            value={formData.state}
            onValueChange={(value) => handleInputChange("state", value)}
          >
            <SelectTrigger>
              <SelectValue placeholder="Select state" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="Telangana">Telangana</SelectItem>
              <SelectItem value="Andhra Pradesh">Andhra Pradesh</SelectItem>
              <SelectItem value="Karnataka">Karnataka</SelectItem>
            </SelectContent>
          </Select>
        </div>

        <div>
          <Label htmlFor="district">District</Label>
          <Select
            value={formData.district}
            onValueChange={(value) => handleInputChange("district", value)}
          >
            <SelectTrigger>
              <SelectValue placeholder="Select district" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="Hyderabad">Hyderabad</SelectItem>
              <SelectItem value="Rangareddy">Rangareddy</SelectItem>
              <SelectItem value="Medchal">Medchal</SelectItem>
            </SelectContent>
          </Select>
        </div>

        <div>
          <Label htmlFor="city">City</Label>
          <Select
            value={formData.city}
            onValueChange={(value) => handleInputChange("city", value)}
          >
            <SelectTrigger>
              <SelectValue placeholder="Select city" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="Hyderabad">Hyderabad</SelectItem>
              <SelectItem value="Secunderabad">Secunderabad</SelectItem>
              <SelectItem value="Warangal">Warangal</SelectItem>
            </SelectContent>
          </Select>
        </div>

        <div>
          <Label htmlFor="company">Company</Label>
          <Select
            value={formData.company}
            onValueChange={(value) => handleInputChange("company", value)}
          >
            <SelectTrigger>
              <SelectValue placeholder="Select company" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="WD-WFM">WD-WFM</SelectItem>
              <SelectItem value="Other">Other</SelectItem>
            </SelectContent>
          </Select>
        </div>

        <div>
          <Label htmlFor="role">Role</Label>
          <Select
            value={formData.role}
            onValueChange={(value) => handleInputChange("role", value)}
          >
            <SelectTrigger>
              <SelectValue placeholder="Select role" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="Staff">Staff</SelectItem>
              <SelectItem value="Manager">Manager</SelectItem>
              <SelectItem value="Admin">Admin</SelectItem>
            </SelectContent>
          </Select>
        </div>

        <div>
          <Label htmlFor="status">Status</Label>
          <Input
            id="status"
            value={formData.status}
            className="bg-gray-100"
            disabled
          />
        </div>

        <div>
          <Label htmlFor="department">Department</Label>
          <Select
            value={formData.department}
            onValueChange={(value) => handleInputChange("department", value)}
          >
            <SelectTrigger>
              <SelectValue placeholder="Select department" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="IT">IT</SelectItem>
              <SelectItem value="HR">HR</SelectItem>
              <SelectItem value="Finance">Finance</SelectItem>
              <SelectItem value="Operations">Operations</SelectItem>
            </SelectContent>
          </Select>
        </div>

        <div>
          <Label htmlFor="employmentType">Employment Type</Label>
          <Select
            value={formData.employmentType}
            onValueChange={(value) =>
              handleInputChange("employmentType", value)
            }
          >
            <SelectTrigger>
              <SelectValue placeholder="Select employment type" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="Full-time">Full-time</SelectItem>
              <SelectItem value="Part-time">Part-time</SelectItem>
              <SelectItem value="Contract">Contract</SelectItem>
              <SelectItem value="Temporary">Temporary</SelectItem>
            </SelectContent>
          </Select>
        </div>

        <div>
          <Label htmlFor="bank">Bank</Label>
          <Select
            value={formData.bank}
            onValueChange={(value) => handleInputChange("bank", value)}
          >
            <SelectTrigger>
              <SelectValue placeholder="Select bank" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="SBI">SBI</SelectItem>
              <SelectItem value="HDFC">HDFC</SelectItem>
              <SelectItem value="ICICI">ICICI</SelectItem>
              <SelectItem value="Axis">Axis</SelectItem>
            </SelectContent>
          </Select>
        </div>

        <div>
          <Label htmlFor="accountType">Account Type</Label>
          <Select
            value={formData.accountType}
            onValueChange={(value) => handleInputChange("accountType", value)}
          >
            <SelectTrigger>
              <SelectValue placeholder="Select account type" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="Savings">Savings</SelectItem>
              <SelectItem value="Current">Current</SelectItem>
              <SelectItem value="Salary">Salary</SelectItem>
            </SelectContent>
          </Select>
        </div>

        <div>
          <Label htmlFor="workStatus">Work Status</Label>
          <Input
            id="workStatus"
            value={formData.workStatus}
            className="bg-gray-100"
            disabled
          />
        </div>

        <div>
          <Label htmlFor="dateType">Date Type</Label>
          <Select
            value={formData.dateType}
            onValueChange={(value) => handleInputChange("dateType", value)}
          >
            <SelectTrigger>
              <SelectValue placeholder="Select date type" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="joining">Joining Date</SelectItem>
              <SelectItem value="birth">Birth Date</SelectItem>
              <SelectItem value="created">Created Date</SelectItem>
            </SelectContent>
          </Select>
        </div>

        <div>
          <Label htmlFor="dateFrom">Date From</Label>
          <Input
            type="date"
            id="dateFrom"
            value={formData.dateFrom}
            onChange={(e) => handleInputChange("dateFrom", e.target.value)}
          />
        </div>

        <div>
          <Label htmlFor="dateTo">Date To</Label>
          <Input
            type="date"
            id="dateTo"
            value={formData.dateTo}
            onChange={(e) => handleInputChange("dateTo", e.target.value)}
          />
        </div>

        <div>
          <Label htmlFor="areaManager">Area Manager</Label>
          <Input
            id="areaManager"
            value={formData.areaManager}
            className="bg-gray-100"
            disabled
          />
        </div>
      </div>

      <div className="flex justify-end space-x-4">
        <Button type="button" variant="outline" onClick={handleReset}>
          Reset
        </Button>
        <Button type="submit">Submit</Button>
      </div>
    </form>
  );
};