import React, { useCallback } from "react";
import {
  DropdownMenuTrigger,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
} from "../ui/dropdown";
import { Avatar, AvatarFallback, AvatarImage } from "../ui/avatar";
import EventBus from "../../services/utilities/event.bus.service";
import { EventList } from "../../../src/api/event-list";
import { getTokenUserDataFromPersistence } from "../../services/auth/auth.service";

export const ProfileAvatar = () => {
    const logout = useCallback(() => {
        EventBus.dispatch(EventList.AUTH.LOGOUT, 'manual');
    }, []);

    const userData = getTokenUserDataFromPersistence()
    
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Avatar>
          <AvatarImage
            className="object-cover size-10"
            src=" https://plus.unsplash.com/premium_photo-1689977871600-e755257fb5f8?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
          />
          <AvatarFallback>{userData?.user?.fName}</AvatarFallback>
        </Avatar>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <DropdownMenuLabel>{userData?.user?.fName}</DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuItem>Profile</DropdownMenuItem>
        <DropdownMenuItem>Settings</DropdownMenuItem>
        <DropdownMenuItem onClick={logout}>Logout</DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};