import React from "react";
import { Input } from "../ui/input";
import { Search } from "lucide-react";
import Timer from "../core/Timer";
import { ProfileAvatar } from "../common/profile-avatar";

export const Header = () => {
  return (
    <header className="bg-white border-b shadow-sm border-b-white">
      <div className="flex items-center justify-between px-4 py-3">
        <div className="flex items-center w-full">
          <div className="relative w-full max-w-md">
            <Search className="absolute text-gray-400 transform -translate-y-1/2 left-2 top-1/2" />
            <Input
              type="search"
              placeholder="Search..."
              className="w-full px-10 pr-3"
            />
          </div>
        </div>
        <div className="flex items-center gap-4 space-x-4">
          <Timer />
          {/* <ProfileAvatar /> */}
        </div>
      </div>
    </header>
  );
};