import React from "react";
import logo from "../logo.png";
import "./Hero.css";
import { isAuthenticated } from "../services/auth/auth.service";
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

const Hero = () => {
  const store = useSelector((state) => state.auth);
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated(store.token)) {
      navigate(`/dashboard`);
    } else {
      navigate(`/login`);
    }
  }, [])

  return (
    <>
      <main className="App-main">
        <img src={logo} className="App-logo" alt="logo" />
        <p>Welcome to White Dolffins.</p>
        <a
          className="App-link"
          href="https://www.whitedolffins.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Official Website
        </a>
      </main>
    </>
  );
};

export default Hero;
