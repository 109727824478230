// import LoginPageComponent from '../pages/Auth/Login';
import LoginPageComponent from "../pages/public/Auth/Login";

const authRoutes = [
  {
    path: "login",
    element: <LoginPageComponent />,
  },
];


export default authRoutes;
