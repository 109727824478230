class EncryptionService {
  static instance = null;
  constructor() {
    if (EncryptionService.instance instanceof EncryptionService) {
      return EncryptionService.instance;
    }
    EncryptionService.instance = this;
  }
  encrypt(data) {
    // can be improved future with better algorythm, may be with crypto(chrome)
    try {
      return btoa(data);
    } catch (error) {
      console.error("Error encrypting data:", error);
      return null;
    }
  }
  decrypt(data) {
    try {
      return atob(data);
    } catch (error) {
      console.error("Error decrypting data:", error);
      return null;
    }
  }
}
const Encryption = new EncryptionService();
export default Encryption;
