// Event Bus
const EventBus = {
  timer: {},
  on(event, callback, multicast = false) {
    if (multicast) {
      // Check Multi Cast
      document.addEventListener(event, (e) => {
        callback(e.detail);
      });
    } else {
      // Check Single Cast
      document.addEventListener(event, (e) => {
        // throtlling is used here
        if (EventBus.timer[event]) {
          window.clearTimeout(EventBus.timer[event]);
        }
        EventBus.timer[event] = window.setTimeout(() => {
          callback(e.detail);
        }, 10);
      });
    }
  },
  dispatch(event, data) {
    // Dispatch
    document.dispatchEvent(new CustomEvent(event, { detail: data }));
  },
  off(event, callback) {
    // Off
    document.removeEventListener(event, callback);
  },
};

export default EventBus;
