import React from "react";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogHeader,
  DialogDescription,
  DialogFooter,
  DialogTitle,
} from "../../../components/ui/dialog";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../../../components/ui/tabs";
import { Button } from "../../../components/ui/button";
export const LogDetails = ({ log_details = {}, open, setOpen }) => {
  if (!log_details || Object.keys(log_details).length === 0) {
    return null;
  }
  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent className="max-w-4xl max-h-[80vh] overflow-y-auto bg-white p-0">
        <DialogHeader className="sticky top-0 z-10 p-6 pb-4 bg-white border-b">
          <DialogTitle className="text-2xl font-semibold text-left ">
            Log Details
          </DialogTitle>
          <DialogDescription className="text-left">
            Detailed information about {log_details.app.name}
          </DialogDescription>
        </DialogHeader>
        <Tabs defaultValue="app" className="w-full">
          <TabsList className="flex justify-start px-2 py-4 bg-gray-100 border-b">
            <TabsTrigger
              value={"app"}
              className="px-4 py-2 text-sm font-medium"
            >
              App
            </TabsTrigger>
            <TabsTrigger
              value={"log"}
              className="px-4 py-2 text-sm font-medium"
            >
              Log
            </TabsTrigger>
            <TabsTrigger
              value={"user"}
              className="px-4 py-2 text-sm font-medium"
            >
              User
            </TabsTrigger>
          </TabsList>
          <div className="p-6">
            <TabsContent value={"app"}>
              <div className="space-y-2">
                {Object.entries(log_details.app).map((item, index) => (
                  <div
                    key={index}
                    className="flex justify-between py-2 border-b border-gray-200"
                  >
                    <p className="font-medium text-gray-600 capitalize">
                      {item[0]}:
                    </p>
                    <p className="text-gray-800 capitalize">{item[1]}</p>
                  </div>
                ))}
              </div>
            </TabsContent>
            <TabsContent value={"log"}>
              <div className="space-y-2">
                {Object.entries(log_details.log).map((item, index) => (
                  <div
                    key={index}
                    className="flex justify-between py-2 border-b border-gray-200"
                  >
                    <p className="font-medium text-gray-600 capitalize">
                      {item[0]}:
                    </p>
                    <p className="text-gray-800 capitalize">{item[1]}</p>
                  </div>
                ))}
              </div>
            </TabsContent>
            <TabsContent value={"user"}>
              <div className="space-y-2">
                {Object.entries(log_details.user).map((item, index) => (
                  <div
                    key={index}
                    className="flex justify-between py-2 border-b border-gray-200"
                  >
                    <p className="font-medium text-gray-600 capitalize">
                      {item[0]}:
                    </p>
                    <p className="text-gray-800 capitalize">{item[1]}</p>
                  </div>
                ))}
              </div>
            </TabsContent>
          </div>
        </Tabs>
        <DialogFooter className="sticky bottom-0 z-10 justify-end p-3 bg-white border-t ">
          <DialogClose asChild>
            <Button type="button">Close</Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};