import React, { ReactNode } from "react";
interface Props {
  title: string;
  value: string | number | undefined | ReactNode;
  direction?: "horizontal" | "vertical";
}
export const SummaryItem = ({
  title,
  value,
  direction = "horizontal",
}: Props) => {
  return (
    <div
      className={`flex p-2 ${
        direction === "horizontal"
          ? "flex-row justify-between"
          : "flex-col gap-1"
      }  `}
    >
      <p className="text-sm font-normal leading-5 text-gray-600 text-wrap">
        {title ?? "N/A"}
      </p>
      <p className="text-base font-normal leading-6 text-black text-wrap">
        {value ?? "N/A"}
      </p>
    </div>
  );
};