import { Outlet } from "react-router-dom";
import Sidebar from "./sidebar.jsx";
import TimerModal from "../common/TimerModal.js";
import { Header } from "./header.jsx";
import { Footer } from "./footer.jsx";
import React, { useCallback, useEffect, useLayoutEffect } from "react";
import EventBus from "../../services/utilities/event.bus.service.js";
import { EventList } from "../../api/event-list.js";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { isAuthenticated } from "../../services/auth/auth.service.js";

const DashboardLayout = () => {
  const store = useSelector((state) => state.auth);
  const navigate = useNavigate();

  const initSubscription = useCallback(() => {
    EventBus.on(EventList.CORE.SESSION_EXPIRY_ALERT, (timeLeft) => {
      EventBus.dispatch(EventList.CORE.SHOW_EXPIRY_ALERT, timeLeft);
    });
  }, []);

  const destroySubscription = useCallback(() => {
    EventBus.off(EventList.CORE.SESSION_EXPIRY_ALERT, () => {});
  }, []);

  useLayoutEffect(() => {
    initSubscription();
  }, []);

  useEffect(() => {
    if (isAuthenticated(store.token)) {
      navigate(`/dashboard`);
    } else {
      navigate(`/login`);
    }
    destroySubscription();
  }, []);

  return (
    <div className="flex h-screen bg-gray-100">
      <TimerModal />
      <Sidebar />
      <div className="flex flex-col flex-1 overflow-hidden">
        <Header />
        <main className="flex-1 p-6 overflow-x-hidden overflow-y-auto bg-gray-100">
          <Outlet />
        </main>
        <Footer />
      </div>
    </div>
  );
};

export default DashboardLayout;