import { useState } from "react";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../components/ui/select";
import { Input } from "../../../components/ui/input";
import { Label } from "../../../components/ui/label";
import { Button } from "../../../components/ui/button";
import { format } from "date-fns";
export const CreateNewEmployee = () => {
  const [phone, setPhone] = useState("");

  const handlePhoneChange = (e) => {
    const value = e.target.value.replace(/\D/g, "");
    if (value.length <= 10) {
      setPhone(value.replace(/(\d{4})(\d{3})(\d{3})/, "$1-$2-$3"));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form submitted");
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <div>
        <Label htmlFor="phone">Phone</Label>
        <Input
          id="phone"
          value={phone}
          onChange={handlePhoneChange}
          placeholder="1111-111-111"
          required
        />
      </div>
      <div>
        <Label htmlFor="company">Company</Label>
        <Select required>
          <SelectTrigger>
            <SelectValue placeholder="Select company" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="WFM">WFM</SelectItem>
            <SelectItem value="IMF">IMF</SelectItem>
          </SelectContent>
        </Select>
      </div>
      <div>
        <Label htmlFor="role">Role</Label>
        <Select required>
          <SelectTrigger>
            <SelectValue placeholder="Select role" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="Admin">Admin</SelectItem>
            <SelectItem value="Area Manager">Area Manager</SelectItem>
            <SelectItem value="Dolffins">Dolffins</SelectItem>
            <SelectItem value="Staff">Staff</SelectItem>
          </SelectContent>
        </Select>
      </div>
      <div>
        <Label htmlFor="department">Department</Label>
        <Select required>
          <SelectTrigger>
            <SelectValue placeholder="Select department" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="Accounting">Accounting</SelectItem>
            <SelectItem value="Customer Support">Customer Support</SelectItem>
            <SelectItem value="Expansion">Expansion</SelectItem>
            <SelectItem value="Ground Fleet">Ground Fleet</SelectItem>
            <SelectItem value="HR">HR</SelectItem>
            <SelectItem value="Legal">Legal</SelectItem>
            <SelectItem value="Marketing">Marketing</SelectItem>
            <SelectItem value="Operations">Operations</SelectItem>
            <SelectItem value="Sales">Sales</SelectItem>
            <SelectItem value="Training">Training</SelectItem>
          </SelectContent>
        </Select>
      </div>
      <div>
        <Label htmlFor="employmentType">Employment Type</Label>
        <Select required>
          <SelectTrigger>
            <SelectValue placeholder="Select employment type" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="Contractual">Contractual</SelectItem>
            <SelectItem value="Full Time">Full Time</SelectItem>
            <SelectItem value="Part Time">Part Time</SelectItem>
          </SelectContent>
        </Select>
      </div>
      <div>
        <Label htmlFor="reportingTo">Reporting To</Label>
        <Input id="reportingTo" required />
      </div>
      <div>
        <Label htmlFor="areaManager">Area Manager</Label>
        <Input id="areaManager" required />
      </div>
      <div>
        <Label htmlFor="status">Status</Label>
        <Input id="status" value="Active" disabled />
      </div>
      <div>
        <Label htmlFor="workStatus">Work Status</Label>
        <Input id="workStatus" value="Available" disabled />
      </div>
      <div>
        <Label htmlFor="resigned">Resigned</Label>
        <Input id="resigned" value="No" disabled />
      </div>
      <div>
        <Label htmlFor="createdOn">Created On</Label>
        <Input
          id="createdOn"
          value={format(new Date(), "dd-MM-yyyy hh:mm a")}
          disabled
        />
      </div>
      <div>
        <Label htmlFor="createdBy">Created By</Label>
        <Input id="createdBy" value="9911991199" disabled />
      </div>
      <div>
        <Label htmlFor="submit">Submit</Label>
        <Input id="submit" value="FALSE" disabled />
      </div>
      <Button type="submit" className="w-full">
        Create Employee
      </Button>
    </form>
  );
};