// this is the list of all events being used throughout the application, events are catagorized based
// on type of use.
export const EventList = {
    "EMPLOYEE": {
        "CREATE_EMPLOYEE": "CREATE_EMPLOYEE",
        "CREATE_EMPLOYEE_SUCCESS": "CREATE_EMPLOYEE_SUCCESS",
        "CREATE_EMPLOYEE_ERROR": "CREATE_EMPLOYEE_ERROR",
        "UPDATE_EMPLOYEE": "UPDATE_EMPLOYEE",
        "UPDATE_EMPLOYEE_SUCCESS": "UPDATE_EMPLOYEE_SUCCESS",
        "UPDATE_EMPLOYEE_ERROR": "UPDATE_EMPLOYEE_ERROR",
        "FETCH_EMPLOYEE_LIST": "FETCH_EMPLOYEE_LIST",
        "FETCH_EMPLOYEE_DETAILS": "FETCH_EMPLOYEE_DETAILS",
        "FETCH_EMPLOYEE_DETAILS_SUCCESS": "FETCH_EMPLOYEE_DETAILS_SUCCESS",
        "FETCH_EMPLOYEE_DETAILS_ERROR": "FETCH_EMPLOYEE_DETAILS_ERROR",
        "FETCH_EMPLOYEE_LIST_NO_CACHE": "FETCH_EMPLOYEE_LIST_NO_CACHE",
        "FETCH_EMPLOYEE_LIST_SUCCESS": "FETCH_EMPLOYEE_LIST_SUCCESS",
        "FETCH_EMPLOYEE_LIST_ERROR": "FETCH_EMPLOYEE_LIST_ERROR",
        "SORT_EMPLOYEE_LIST": "SORT_EMPLOYEE_LIST_",
        "SORT_EMPLOYEE_LIST_SUCCESS": "SORT_EMPLOYEE_LIST_SUCCESS",
        "FILTER_EMPLOYEE_LIST": "FILTER_EMPLOYEE_LIST_",
        "FILTER_EMPLOYEE_LIST_SUCCESS": "FILTER_EMPLOYEE_LIST_SUCCESS",
        "SET_PER_PAGE_DATA": "SET_PER_PAGE_DATA",
        "SET_START_INDEX": "SET_START_INDEX",
        "EMPLOYEE_DATA_FORM_VALIDATION_FAILED": "EMPLOYEE_DATA_FORM_VALIDATION_FAILED",
        "EMPLOYEE_DATA_FORM_VALIDATION_SUCCEDED": "EMPLOYEE_DATA_FORM_VALIDATION_SUCCEDED"
    },
    "CORE": {
        "FETCH_DROPDOWN_DATA": "FETCH_DROPDOWN_DATA",
        "FETCH_DROPDOWN_DATA_SUCCESS": "FETCH_DROPDOWN_DATA_SUCCESS",
        "FETCH_DROPDOWN_DATA_ERROR": "FETCH_DROPDOWN_DATA_ERROR",
        "SHOW_POPUP": "SHOW_POPUP",
        "HIDE_POPUP": "HIDE_POPUP",
        "SHOW_LOADER": "SHOW_LOADER",
        "HIDE_LOADER": "HIDE_LOADER",
        "SESSION_EXPIRY_ALERT": "SESSION_EXPIRY_ALERT",
        "SHOW_EXPIRY_ALERT": "SHOW_EXPIRY_ALERT",
        "HIDE_EXPIRY_ALERT": "HIDE_EXPIRY_ALERT",
    },
    "AUTH": {
        "LOGOUT": "LOGOUT",
        "SEND_OTP": "SEND_OTP",
        "SEND_BACKUP_OTP": "SEND_BACKUP_OTP",
        "OTP_SEND_SUCCESSFULLY": "OTP_SEND_SUCCESSFULLY",
        "OTP_SENDING_FAILED": "OTP_SENDING_FAILED",
        "SUBMIT_OTP": "SUBMIT_OTP",
        "LOGIN_SUCCESSFULL": "LOGIN_SUCCESSFULL",
        "LOGIN_FAILED": "LOGIN_FAILED",
        "DISPLAY_OTP_DIV": "DISPLAY_OTP_DIV",
        "DISPLAY_OTP_EXPIRED_ERROR": "DISPLAY_OTP_EXPIRED_ERROR",
        "LOGOUT_SUCCESSFUL": "LOGOUT_SUCCESSFUL",
    }
};