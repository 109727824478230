import React from "react";
import { Phone, Mail, MessageSquare } from "lucide-react";
export const Footer = () => {
  return (
    <footer className="bg-white shadow-md mt-auto">
      <div className="container mx-auto px-6 py-3 flex justify-center items-center">
        Crafted with Love @ White Dolffins.
      </div>
    </footer>
  );
};