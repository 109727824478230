import { setIsLoading, setLogs } from "../../redux/reducers/app-layout-reducer";
import { store } from "../../redux/store";
import { getTokenFromPersistance } from "../auth/auth.service";
import axios from "../utilities/axios.service";

const tokenFromStorage = getTokenFromPersistance();
export const getLogs = async () => {
  store.dispatch(setIsLoading(true));
  try {
    const res = await axios.get("/api/public/gld/1/10", {
      headers: { Authorization: `${tokenFromStorage}` },
    });
    store.dispatch(setLogs(res.data));
    store.dispatch(setIsLoading(false));
    return res.data;
  } catch (error) {
    store.dispatch(setIsLoading(false));

    console.log(error);
  }
};