import React, { useState } from "react";
import { Input } from "../../components/ui/input";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../components/ui/table";
import { PageTitle } from "../../components/ui/page-title";
export const Settings = () => {
  const [filter, setFilter] = useState("");
  // Mock data - replace with actual API call
  const data = [
    { id: 1, name: "John Doe", email: "john@example.com", status: "Active" },
    {
      id: 2,
      name: "Jane Smith",
      email: "jane@example.com",
      status: "Inactive",
    },
    { id: 3, name: "Bob Johnson", email: "bob@example.com", status: "Active" },
    {
      id: 4,
      name: "Alice Brown",
      email: "alice@example.com",
      status: "Inactive",
    },
    {
      id: 5,
      name: "Charlie Davis",
      email: "charlie@example.com",
      status: "Active",
    },
  ];
  const filteredData = data.filter(
    (item) =>
      item.name.toLowerCase().includes(filter.toLowerCase()) ||
      item.email.toLowerCase().includes(filter.toLowerCase())
  );
  return (
    <div>
      <PageTitle>Settings</PageTitle>
      <Input
        type="text"
        placeholder={`Filter settings...`}
        value={filter}
        onChange={(e) => setFilter(e.target.value)}
        className="mb-4"
      />
      <Table>
        <TableHeader>
          <TableRow className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
            <TableHead>Name</TableHead>
            <TableHead>Email</TableHead>
            <TableHead>Status</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {filteredData.map((item) => {
            return (
              <TableRow
                key={item.id}
                className=" border-b border-gray-200 hover:bg-gray-100"
              >
                <TableCell>{item.name}</TableCell>
                <TableCell>{item.email}</TableCell>
                <TableCell>
                  {" "}
                  <span
                    className={`py-1 px-3 rounded-full text-xs ${
                      item.status === "Active"
                        ? "bg-green-200 text-green-600"
                        : "bg-red-200 text-red-600"
                    }`}
                  >
                    {item.status}
                  </span>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
};