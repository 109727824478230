import { createSlice } from "@reduxjs/toolkit";
// import { action_setToken, action_deleteToken } from "../actions/auth.action";
import { action_setToken, action_deleteToken } from "../actions/auth.actions";
import { checkTokenExistence, deleteTokenFromPersistence, getTokenFromPersistance, setTokenForPersistance, setTokenUserDataForPersistence } from "../../services/auth/auth.service";
import { jwtDecode } from "jwt-decode";
// this reducer is for updating joke data category wise
let initialState = {
    token: null,
    user: null,
};
if (checkTokenExistence()) {
    initialState = {
        token: getTokenFromPersistance()
    }
}
const authSlice = createSlice({
    name: "auth",
    initialState,
    extraReducers: (builder) => {
        builder.addCase(action_setToken.fulfilled, (state, token) => {
            console.log("tokenObj", token);
            setTokenForPersistance(token.payload);
            state.token = token.payload;
            const tokenUserData = jwtDecode(token.payload.split("Begger ")[1]);
            setTokenUserDataForPersistence(tokenUserData)
            state.user = tokenUserData;
        });
        builder.addCase(action_deleteToken.fulfilled, (state) => {
            deleteTokenFromPersistence();
            return {
                ...state,
                token: null
            };
        });
    }
});
const reducer_auth = authSlice.reducer;
export default reducer_auth;