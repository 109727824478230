import { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import "./Loader.css";
import { EventList } from "../../api/event-list";
import EventBus from "../../services/utilities/event.bus.service";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogTitle,
  DialogHeader,
  DialogTrigger,
} from "../ui/dialog";
import { Button } from "../ui/button";
export default function TimerModal() {
  const opacityRef = useRef(null);
  const [timeLeft, setTimeLeft] = useState(10);
  const [showModal, setShowModal] = useState(false);
  const toggleLoader = (show, timeLeft) => {
    setTimeLeft(timeLeft);
    if (!show) opacityRef?.current?.classList?.remove("modal-open");
    else opacityRef?.current?.classList?.add("modal-open");
  };

  const closeTimerModal = () => {
    EventBus.dispatch(EventList.CORE.HIDE_EXPIRY_ALERT);
  };
  useEffect(() => {
    EventBus.on(EventList.CORE.SHOW_EXPIRY_ALERT, (timeLeft) => {
      toggleLoader(true, timeLeft);
    });
    EventBus.on(EventList.CORE.HIDE_EXPIRY_ALERT, () => {
      toggleLoader(false);
    });
  }, []);
  useEffect(() => () => {
    EventBus.off(EventList.CORE.SHOW_EXPIRY_ALERT, () => {
      toggleLoader(true);
    });
    EventBus.off(EventList.CORE.HIDE_EXPIRY_ALERT, () => {
      toggleLoader(false);
    });
  });

  //For testing purpose, I open the modal after 10 seconds
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setShowModal(true);
    }, 10000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  return (
    <Dialog open={showModal} onOpenChange={setShowModal}>
      <DialogContent className="sm:max-w-[425px]">
        <div className="" ref={opacityRef} onClick={toggleLoader}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Session Expiry Alert</h4>
              </div>
              <div className="modal-body">
                <span>
                  Your Session will auto logout in {timeLeft} minutes, please
                  save your work. You can logout and login to continue working
                  uninterrupted.
                </span>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={closeTimerModal}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}