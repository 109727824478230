import React, { Suspense, useCallback, useEffect, useLayoutEffect } from "react";
import "./loginForm.css";
import logo from "../../../../src/assets/images/logo.png";
import ParticlesBackground from "../../../components/common/ParticlesBackground";
import EventBus from "../../../services/utilities/event.bus.service";
import { EventList } from "../../../api/event-list";
import { sendOTP, submitOTP, callBackupLogin, submitIMLogoutRequest, isAuthenticated } from "../../../services/auth/auth.service";
import toast, { Toaster } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { action_setToken } from "../../../redux/actions/auth.actions";
import Loader from "../../../components/common/Loader";

const LoginForm = React.lazy(() => import("./loginForm"));

function Login() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const store = useSelector((state) => state.auth);

    const initSubscription = useCallback( () => {
        EventBus.on(EventList.AUTH.SEND_OTP, (phoneNumber) => {
            EventBus.dispatch(EventList.CORE.SHOW_LOADER);
            sendOTP(phoneNumber);
        });

        EventBus.on(EventList.AUTH.OTP_SEND_SUCCESSFULLY, () => {
            EventBus.dispatch(EventList.CORE.HIDE_LOADER);
            toast.success("OTP sent to mobile number.");
            const errorMessageDOM = document.getElementById("div_errorMessage")
            errorMessageDOM.style.display = "none";
            EventBus.dispatch(EventList.AUTH.DISPLAY_OTP_DIV);
            EventBus.dispatch(EventList.CORE.HIDE_LOADER);
        })

        EventBus.on(EventList.AUTH.OTP_SENDING_FAILED, (error) => {
            EventBus.dispatch(EventList.CORE.HIDE_LOADER);
            const errorMessage = error?.response?.data?.message ?? "OTP sending failed, check phone number or try latter!";
            toast.error(errorMessage);
            const errorMessageDOM = document.getElementById("div_errorMessage");
            errorMessageDOM.innerText = errorMessage;
            errorMessageDOM.style.display = "block";
            EventBus.dispatch(EventList.CORE.HIDE_LOADER);
        }, true);

        EventBus.on(EventList.AUTH.SUBMIT_OTP, (data) => {
            submitOTP(data);
            EventBus.dispatch(EventList.CORE.SHOW_LOADER);
        });

        EventBus.on(EventList.AUTH.LOGIN_SUCCESSFULL, (data) => {
            EventBus.dispatch(EventList.CORE.HIDE_LOADER);
            //sessionStorage["token"] = data.token;// will be changed soon
            dispatch(action_setToken(data.data.token))
            navigate("/dashboard");
        });

        EventBus.on(EventList.AUTH.LOGIN_FAILED, (data) => {
            EventBus.dispatch(EventList.CORE.HIDE_LOADER);
            // const errorMessage = data?.response?.data?.message ?? "Login failed, check phone number or otp!";
            const errorMessage = 'Login failed';
            toast.error(errorMessage);
            const errorMessageDOM = document.getElementById("div_errorMessage")
            errorMessageDOM.innerText = errorMessage;
            errorMessageDOM.style.display = "block";
            // if (data && data.message && data.message.includes("LDI")) {
            //     EventBus.dispatch(EventList.AUTH.DISPLAY_IMPROPER_LOGOUT_DIV);
            // }
        });

        EventBus.on(EventList.AUTH.SEND_BACKUP_OTP, (data) => {
            EventBus.dispatch(EventList.CORE.SHOW_LOADER);
            callBackupLogin(data.phoneNumber, data.mode);
        });

        EventBus.on(EventList.AUTH.DISPLAY_OTP_EXPIRED_ERROR, () => {
            toast.error("OTP expired!");
            const errorMessageDOM = document.getElementById("div_errorMessage")
            errorMessageDOM.innerText = "OTP expired!";
            errorMessageDOM.style.display = "block";
        });

        EventBus.on(EventList.AUTH.SEND_IMLOGOUT_REQUEST, (data) => {
            submitIMLogoutRequest(data);
        })
    }, []);

    const destroySubscription = useCallback( () => {
        EventBus.off(EventList.AUTH.SEND_OTP, (phoneNumber) => {

        });
        EventBus.off(EventList.AUTH.OTP_SEND_SUCCESSFULLY, () => {
        });
        EventBus.off(EventList.AUTH.OTP_SENDING_FAILED, () => {
        });
        EventBus.off(EventList.AUTH.SUBMIT_OTP, (data) => {

        });
        EventBus.off(EventList.AUTH.LOGIN_SUCCESSFULL, (data) => {

        });
        EventBus.off(EventList.AUTH.LOGIN_FAILED, (data) => {

        });
        EventBus.off(EventList.AUTH.SEND_BACKUP_OTP, (data) => {
        });
        EventBus.off(EventList.AUTH.DISPLAY_OTP_EXPIRED_ERROR, () => {

        });
        EventBus.off(EventList.AUTH.SEND_IMLOGOUT_REQUEST, (data) => { })
    }, []);

    useLayoutEffect(() => {
        initSubscription();
    }, []);

    useEffect(() => {
        destroySubscription();
        if (isAuthenticated(store.token)) {
            navigate(`/dashboard`);
          } else {
            navigate(`/login`);
        }
    }, []);
    return (
        <>
            <Loader/>
            <div className="page-content">
                {/* Main content */}
                <div className="content-wrapper">
                    {/* Inner content */}
                    <div className="row">
                        <div className="col-xl-6 p-0 mob1">
                            <div id="particles-js">
                            <ParticlesBackground />
                                <div className="login-img">
                                    <img src={logo} className="login-logo" />
                                    <h4 className="text-center sacramento White mt-3 mb-0"> White Dolffins</h4>
                                    <p className="text-center sub-text">Work Flow Management</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-md-12 p-0">
                            {/* Sales stats */}
                            <div className="card h-full">
                                {/* Content area */}
                                <div className="content d-flex justify-content-center align-items-center mob2">
                                    {/* Login card */}
                                    <Suspense fallback={<div> Please Wait... </div>} >
                                        <LoginForm />
                                    </Suspense>
                                    {/* /content area */}
                                </div>
                            </div>
                        </div>
                        {/* /inner content */}
                    </div>
                    {/* /main content */}
                </div>
            </div>
            <Toaster
                position="top-right"
                reverseOrder={false}
             />
        </>
    )
}
const LoginComponent = React.memo(Login);
export default LoginComponent;