import React from "react";
import { AlertCircle, Home } from "lucide-react";
import { Button } from "../components/ui/button";
import { Link } from "react-router-dom";
export const NotFound = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-background text-foreground">
      <AlertCircle className="w-20 h-20 text-muted-foreground mb-8" />
      <h1 className="text-4xl font-bold mb-4">404 - Page Not Found</h1>
      <p className="text-xl text-muted-foreground mb-8">
        Oops! The page you're looking for doesn't exist.
      </p>
      <Button
        asChild
        variant="ghost"
        className=" bg-black text-white hover:bg-white hover:text-black"
      >
        <Link to="/dashboard" className="flex items-center">
          <Home className="mr-2 h-4 w-4" />
          Go back home
        </Link>
      </Button>
    </div>
  );
};