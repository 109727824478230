import { createBrowserRouter } from "react-router-dom";
import AuthRoutes from "./authRoutes";
import Hero from "../components/Hero";
// import EmpRoutes from "./employeeRoutes";
// import Dashboard from "../pages/Others/Dashboard";
import { NotFound } from "../pages/not-found.jsx";
import Dashboard from "../pages/Others/dashboard.jsx";
import { Employees } from "../pages/Others/employees.jsx";
import { EmployeesP } from "../pages/Others/employees-p.jsx";
import { Customers } from "../pages/Others/customers.jsx";
import { Vendors } from "../pages/Others/vendors.jsx";
import { Orders } from "../pages/Others/orders.jsx";
import { Webhook } from "../pages/Others/webhook.jsx";
import { Settings } from "../pages/Others/settings.jsx";
import { Profile } from "../pages/Others/profile.jsx";
import DashboardLayout from "../components/layout/dashboard-layout.jsx";
import Logs from "../pages/Others/logs.jsx";

const routes = [
  ...AuthRoutes,
  {
    path: '/',
    element: <Hero/>
  },
  {
    path: "/dashboard",
    element: <DashboardLayout />,
    children: [
      {
        index: true,
        element: <Dashboard />,
      },
      {
        path: "employees",
        element: <Employees />,
      },
      {
        path: "employees-p",
        element: <EmployeesP />,
      },
      {
        path: "customers",
        element: <Customers />,
      },
      {
        path: "vendors",
        element: <Vendors />,
      },
      {
        path: "orders",
        element: <Orders />,
      },
      {
        path: "webhook",
        element: <Webhook />,
      },
      {
        path: "settings",
        element: <Settings />,
      },
      {
        path: "profile",
        element: <Profile />,
      },
      {
        path: "logs",
        element: <Logs />,
      },
    ],
  },
  {
    path: "*",
    element: <NotFound />,
  },
  //add routes from routes.json file here
];

const masterRouter = createBrowserRouter(routes);

export default masterRouter;