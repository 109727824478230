import React, { useState } from "react";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../components/ui/select";
import { Button } from "../../components/ui/button";
import { Input } from "../../components/ui/input";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../components/ui/popover";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../components/ui/table";
import { format } from "date-fns";
import { Calendar } from "../../components/ui/calender";
import { PageTitle } from "../../components/ui/page-title";
import {
  Tabs,
  TabsList,
  TabsTrigger,
  TabsContent,
} from "../../components/ui/tabs";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTrigger,
  DialogTitle,
} from "../../components/ui/dialog";
import { EmployeeDetailsSlideDrawer } from "../../features/employees/components/employee-details-slide-drawer";
import { EmployeeSearchForm } from "../../features/employees/components/employee-search-form";
import { CreateNewEmployee } from "../../features/employees/components/create-new-employee";
import { EmployeeDetailsModal } from "../../features/employees/components/employee-details-modal";
import { getEmployees } from "../../services/employee/employee.service";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { formatTableDate } from "../../utils/shared";
export const EmployeesP = () => {
  const [searchText, setSearchText] = useState("");
  const [department, setDepartment] = useState("all");
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [filter, setFilter] = useState("");
  const [searchColumn, setSearchColumn] = useState("name");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [isDetailOpen, setIsDetailOpen] = useState(false);

  const data = [
    {
      id: 1,
      name: "John Doe",
      email: "john@example.com",
      department: "IT",
      joinDate: "2023-01-15",
    },
    {
      id: 2,
      name: "Jane Smith",
      email: "jane@example.com",
      department: "HR",
      joinDate: "2023-02-20",
    },
    {
      id: 3,
      name: "Bob Johnson",
      email: "bob@example.com",
      department: "Sales",
      joinDate: "2023-03-10",
    },
    {
      id: 4,
      name: "Alice Brown",
      email: "alice@example.com",
      department: "Marketing",
      joinDate: "2023-04-05",
    },
    {
      id: 5,
      name: "Charlie Davis",
      email: "charlie@example.com",
      department: "Finance",
      joinDate: "2023-05-01",
    },
  ];

  const filteredData = data.filter((item) => {
    const matchesFilter = item[searchColumn]
      .toLowerCase()
      .includes(filter.toLowerCase());
    const matchesDateRange =
      (!startDate || item.joinDate >= startDate) &&
      (!endDate || item.joinDate <= endDate);
    return matchesFilter && matchesDateRange;
  });

  const handleRowClick = (employee) => {
    setSelectedEmployee(employee);
    setIsDetailOpen(true);
  };

  // const filteredData = data.filter(
  //   (item) =>
  //     (item.name.toLowerCase().includes(searchText.toLowerCase()) ||
  //       item.email.toLowerCase().includes(searchText.toLowerCase())) &&
  //     (department === "all" || item.department === department) &&
  //     (!fromDate || new Date(item.joinDate) >= fromDate) &&
  //     (!toDate || new Date(item.joinDate) <= toDate)
  const handleSearch = (searchData) => {
    console.log("Search data:", searchData);
  };
  useEffect(() => {
    getEmployees();
  }, []);
  const { employeesList, isLoading } = useSelector((state) => state.appLayout);
  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (employeesList.length === 0) {
    return <></>;
  }

  return (
    <>
      <EmployeeDetailsModal
        isOpen={isDetailOpen}
        onClose={() => setIsDetailOpen(false)}
        employee={selectedEmployee}
      />
      <div>
        <div className="flex items-center justify-between mb-6">
          <PageTitle>Employees-P</PageTitle>
          <div className="flex items-center justify-between mb-6">
            <Dialog>
              <DialogTrigger asChild>
                <Button>Create Employee</Button>
              </DialogTrigger>
              <DialogContent className="sm:max-w-[425px] w-[95vw] max-w-[95vw] sm:w-full md:max-w-xl lg:max-w-2xl">
                <DialogHeader>
                  <DialogTitle>Create New Employee</DialogTitle>
                </DialogHeader>
                <div className="max-h-[60vh] overflow-y-auto px-1 py-2">
                  <CreateNewEmployee />
                </div>
              </DialogContent>
            </Dialog>
          </div>
        </div>
        <Tabs defaultValue="new-recruits">
          <TabsList>
            <TabsTrigger value="new-recruits">New Recruits</TabsTrigger>
            <TabsTrigger value="verified">Verified</TabsTrigger>
          </TabsList>
          <TabsContent value="new-recruits">
            <div className="space-y-6 ">
              <EmployeeSearchForm onSearch={handleSearch} />
              <div>
                <div className="grid grid-cols-1 gap-4 mb-6 md:grid-cols-2 lg:grid-cols-4">
                  <Input
                    type="text"
                    placeholder="Search by name or email"
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                  />
                  <Select value={department} onValueChange={setDepartment}>
                    <SelectTrigger>
                      <SelectValue placeholder="Select department" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="all">All Departments</SelectItem>
                      <SelectItem value="IT">IT</SelectItem>
                      <SelectItem value="HR">HR</SelectItem>
                      <SelectItem value="Sales">Sales</SelectItem>
                      <SelectItem value="Marketing">Marketing</SelectItem>
                      <SelectItem value="Finance">Finance</SelectItem>
                    </SelectContent>
                  </Select>
                  <Popover>
                    <PopoverTrigger asChild>
                      <Button variant="outline">
                        {fromDate ? format(fromDate, "PPP") : "From Date"}
                      </Button>
                    </PopoverTrigger>
                    <PopoverContent className="w-auto p-0">
                      <Calendar
                        mode="single"
                        selected={fromDate}
                        onSelect={setFromDate}
                        autoFocus
                      />
                    </PopoverContent>
                  </Popover>
                  <Popover>
                    <PopoverTrigger asChild>
                      <Button variant="outline">
                        {toDate ? format(toDate, "PPP") : "To Date"}
                      </Button>
                    </PopoverTrigger>
                    <PopoverContent className="w-auto p-0">
                      <Calendar
                        mode="single"
                        selected={toDate}
                        onSelect={setToDate}
                      />
                    </PopoverContent>
                  </Popover>
                </div>
                <Table>
                  <TableHeader>
                    <TableRow className="uppercase bg-gray-100 hover:bg-gray-100">
                      <TableHead className="">Name</TableHead>
                      <TableHead>Email</TableHead>
                      <TableHead>Department</TableHead>
                      <TableHead>Join Date</TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {employeesList.map((employee) => {
                      return (
                        <TableRow
                          key={employee._id}
                          className="border-b border-gray-200 cursor-pointer hover:bg-gray-100"
                          // onClick={() => handleRowClick(employee)}
                        >
                          <TableCell>{employee.employeeFullName}</TableCell>
                          <TableCell>
                            {employee.employeeEmailPersonal}
                          </TableCell>
                          <TableCell>{employee.employeeDepartment}</TableCell>
                          <TableCell>
                            {formatTableDate(employee.employeeDateOfJobStart)}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </div>
            </div>
          </TabsContent>
          <TabsContent value="verified">
            <div className="space-y-6 ">
              <EmployeeSearchForm onSearch={handleSearch} />
              <div>
                <div className="grid grid-cols-1 gap-4 mb-6 md:grid-cols-2 lg:grid-cols-4">
                  <Input
                    type="text"
                    placeholder="Search by name or email"
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                  />
                  <Select value={department} onValueChange={setDepartment}>
                    <SelectTrigger>
                      <SelectValue placeholder="Select department" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="all">All Departments</SelectItem>
                      <SelectItem value="IT">IT</SelectItem>
                      <SelectItem value="HR">HR</SelectItem>
                      <SelectItem value="Sales">Sales</SelectItem>
                      <SelectItem value="Marketing">Marketing</SelectItem>
                      <SelectItem value="Finance">Finance</SelectItem>
                    </SelectContent>
                  </Select>
                  <Popover>
                    <PopoverTrigger asChild>
                      <Button variant="outline">
                        {fromDate ? format(fromDate, "PPP") : "From Date"}
                      </Button>
                    </PopoverTrigger>
                    <PopoverContent className="w-auto p-0">
                      <Calendar
                        mode="single"
                        selected={fromDate}
                        onSelect={setFromDate}
                        autoFocus
                      />
                    </PopoverContent>
                  </Popover>
                  <Popover>
                    <PopoverTrigger asChild>
                      <Button variant="outline">
                        {toDate ? format(toDate, "PPP") : "To Date"}
                      </Button>
                    </PopoverTrigger>
                    <PopoverContent className="w-auto p-0">
                      <Calendar
                        mode="single"
                        selected={toDate}
                        onSelect={setToDate}
                      />
                    </PopoverContent>
                  </Popover>
                </div>
                <Table>
                  <TableHeader>
                  <TableRow className="uppercase bg-gray-100 hover:bg-gray-100 ">
                      <TableHead className="">Name</TableHead>
                      <TableHead>Email</TableHead>
                      <TableHead>Department</TableHead>
                      <TableHead>Join Date</TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {employeesList.map((employee) => {
                      return (
                        <TableRow
                          key={employee._id}
                          className=""
                          // onClick={() => handleRowClick(employee)}
                        >
                          <TableCell>{employee.employeeFullName}</TableCell>
                          <TableCell>
                            {employee.employeeEmailOfficial ??
                              employee.employeeEmailPersonal}
                          </TableCell>
                          <TableCell>{employee.employeeDepartment}</TableCell>
                          <TableCell>
                            {employee.employeeDateOfJobStart}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </div>
            </div>
          </TabsContent>
        </Tabs>
      </div>
    </>
  );
};